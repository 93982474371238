import { useState } from 'react';
import {
  OutlinedInput, InputLabel, Checkbox, FormControlLabel,
} from '@mui/material';
import { Info } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { List } from 'lib/types';
import { useTeams } from 'lib/contexts/teamsContext';
import { userIsAdmin, userIsProOrAdmin, userIsTeamMember } from 'lib/helpers';
import { MobileTooltip } from 'components/MobileTooltip';
import { User } from 'lib/types/users';
import { useSelectedUser } from 'components/UserSearch/useSelectedUser';
import { UserSearch } from 'components/UserSearch';
import { toSlug } from 'lib/utils/filterUtils';
import Link from 'next/link';
import { TeamSelect } from './TeamSelect';
import { ListSelect } from './ListSelect';

interface Props {
  lists: List[];
  onSave: (list: List) => void;
  onCancel: () => void;
  list?: List;
}

export const AddEditList = ({
  lists, list, onSave, onCancel,
}: Props) => {
  const currentUser = useCurrentUser();
  const API = useApi();
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState<string>(list?.name || '');
  const [teamId, setTeamId] = useState<number | undefined>(list?.teamId);
  const [copyFromListId, setCopyFromListId] = useState<number | undefined>();
  const [isPrivate, setIsPrivate] = useState<boolean>(list?.isPrivate || true);

  const teams = useTeams();
  /* teams that user's a member of */
  const myTeams = teams?.filter(
    (t) => t.members.find((m) => m.userId === currentUser?.id),
  ) || [];

  const [users, setUsers] = useState<User[]>([]);
  const { onHandleSelectUser } = useSelectedUser(null);

  const onSelectAccount = (value: User[]) => {
    setUsers(value.filter((u) => !!u.id));
    const toAdd = value.filter((u) => !u.id);
    toAdd.map(async (u) => onHandleSelectUser(u));
  };

  const saveList = async () => {
    if (!currentUser) return;

    try {
      let result: List;
      if (list?.id) {
        result = await API.editList({
          ...list,
          name,
          teamId,
          isPrivate,
        });
        enqueueSnackbar(`${name} has been updated.`, {
          variant: 'success',
        });
      } else {
        result = await API.addList({
          name,
          teamId,
          ownerId: teamId ? undefined : currentUser.id,
          copyFromListId,
          isPrivate,
          users,
        });
        const action = () => (
          <button type="button" className="btn">
            <Link href={`/creators?list=${toSlug(result.name, result.publicId)}`}>
              View list
            </Link>
          </button>
        );
        enqueueSnackbar(`${name} has been created.`, {
          variant: 'success',
          action,
        });
      }
      onSave(result);
      onCancel();
    } catch (error) {
      enqueueSnackbar(`Error saving list: ${error}`, {
        variant: 'error',
      });
    }
  };

  const changeTeam = (id?: number) => {
    setTeamId(id);
  };

  return (
    <div>
      <h1 className="text-lg font-semibold mb-4">
        {list ? 'Edit List' : 'Create a New List'}
      </h1>
      <div className="mb-4">
        <InputLabel id="list-name-label" className="font-semibold mb-2">Name</InputLabel>
        <OutlinedInput
          type="text"
          id="name"
          name="name"
          placeholder="Name"
          value={name}
          className="w-full"
          size="small"
          onChange={
            (e) => {
              setName(e.target.value);
            }
          }
        />
      </div>
      <div className="mb-4">
        <FormControlLabel
          control={(
            <Checkbox
              checked={isPrivate}
              onChange={() => setIsPrivate(!isPrivate)}
              name="isPrivate"
              color="primary"
              disabled={!userIsProOrAdmin(currentUser) && !userIsTeamMember(currentUser)}
            />
          )}
          label="Private" // todo: better label?
        />
        <MobileTooltip
          placement="top"
          arrow
          title="Lists are automatically private, but you can make them public if you want to share them with others."
        >
          <Info color="secondary" />
        </MobileTooltip>
      </div>
      {!list && myTeams.length >= 1 && (
        <TeamSelect
          teams={myTeams}
          selected={teamId}
          onChange={changeTeam}
        />
      )}
      {!list?.id && (
        <div className="mb-4">
          <UserSearch
            label="Add creators to your new list"
            onChange={(_, value) => onSelectAccount(value)}
            isCreatableSearch
            multiple
            value={users}
          />
        </div>
      )}
      {userIsAdmin(currentUser) && !list && lists.length > 0 && (
        <ListSelect
          label="Copy creators from another list (admin only)"
          lists={lists}
          selected={copyFromListId}
          onChange={setCopyFromListId}
        />
      )}
      <div className="flex gap-2 justify-end">
        <button type="button" className="btn-primary-outlined" onClick={onCancel}>Cancel</button>
        <button type="button" className="btn-primary" onClick={saveList} disabled={!name.trim()}>Save</button>
      </div>
    </div>
  );
};
