import { useState } from 'react';
import { reject } from 'lodash';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { useSignUpModal } from 'components/SignUpModal';
import { SaveToListModal } from 'components/SaveToList/SaveToListModal';
import { trackClickSignupEvent } from 'lib/hooks/useEventTracking';

interface Props {
  userId: number;
  listsFromProps: number[];
}

export const SaveToListButton = ({
  userId, listsFromProps,
}: Props) => {
  const currentUser = useCurrentUser();
  const { showSignUpModal } = useSignUpModal();

  const [lists, setLists] = useState<number[]>(listsFromProps || []);
  const [saveOpen, setSaveOpen] = useState(false);
  const API = useApi();

  const savedToList = (id: number) => {
    setLists([...lists, id]);
    setSaveOpen(false);
  };

  const removedFromList = (id: number) => {
    setLists(reject(lists, (n) => n === id));
    setSaveOpen(false);
  };

  const click = () => {
    if (!currentUser) {
      trackClickSignupEvent(API, 'save-creator-to-list');
      showSignUpModal('Please sign up or sign in to save creators');
      return;
    }
    setSaveOpen(true);
  };

  const buttonToDisplay = () => {
    if (lists?.length > 1) {
      return (
        <div className="relative">
          <div className="absolute -top-1 -right-0 font-bold bg-white rounded-full px-1 text-sm">{lists?.length}</div>
          <BookmarksIcon />
        </div>
      );
    }
    if (lists?.length === 1) {
      return <BookmarkAddedIcon />;
    }
    return <BookmarkAddOutlinedIcon />;
  };

  return (
    <div className="text-orange">
      {saveOpen && (
        <SaveToListModal
          userId={userId}
          onAdd={savedToList}
          onRemove={removedFromList}
          onCancel={() => setSaveOpen(false)}
          listIds={lists}
        />
      )}
      <button
        type="button"
        onClick={click}
        aria-label="save or unsave user"
        color="inherit"
        title="Save or unsave user"
        className="hover:bg-hintgray/50 rounded-full"
      >
        {buttonToDisplay()}
      </button>
    </div>
  );
};
