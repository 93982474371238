import { useState } from 'react';
import {
  Dialog, IconButton,
  List as MuiList, ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import BookmarkRemove from '@mui/icons-material/BookmarkRemove';
import { useSnackbar } from 'notistack';
import { useApi, useCurrentUser } from 'lib/contexts/ApplicationState';
import { useUserLists } from 'lib/contexts/userListsContext';
import { List } from 'lib/types';
import { userIsProOrAdmin, userIsTeamMember } from 'lib/helpers';
import { toSlug } from 'lib/utils/filterUtils';
import { useUpgradeModal } from 'components/UpgradeModal';
import { AddEditList } from 'components/AddEditList';
import { FAVORITES_LIST_NAME } from 'lib/constants';
import Link from 'next/link';

interface Props {
  userId: number;
  listIds?: number[];
  onAdd: (listId: number) => void;
  onRemove: (listId: number) => void;
  onCancel: () => void;
}

export const SaveToListModal = ({
  userId, listIds = [], onAdd, onRemove, onCancel,
}: Props) => {
  const currentUser = useCurrentUser();
  const API = useApi();
  const { enqueueSnackbar } = useSnackbar();
  const { showUpgradeModal } = useUpgradeModal();

  const [isSendingData, setIsSendingData] = useState(false);
  const { lists, setLists } = useUserLists();
  const [adding, setAdding] = useState(false);

  const addToList = async (list: List) => {
    try {
      setIsSendingData(true);
      await API.addUserToList(list.id, userId);
      setIsSendingData(false);
      onAdd(list.id);
      const action = () => (
        <button type="button" className="btn">
          <Link href={`/creators?list=${toSlug(list.name, list.publicId)}`}>
            View list
          </Link>
        </button>
      );
      enqueueSnackbar(`Added to ${list.name}!`, {
        variant: 'success',
        action,
        autoHideDuration: 9000,
      });
    } catch (error) {
      enqueueSnackbar(`Error adding creator to list: ${error}`, {
        variant: 'error',
      });
    }
  };

  const removeFromList = async (list: List) => {
    try {
      setIsSendingData(true);
      await API.removeUserFromList(list.id, userId);
      setIsSendingData(false);
      onRemove(list.id);
      enqueueSnackbar(`Removed from ${list.name}`, {
        variant: 'success',
      });
    } catch (err) {
      setIsSendingData(false);
      enqueueSnackbar(`Error removing creator from list: ${err}`, {
        variant: 'error',
      });
    }
  };

  const save = async (list: List, added: boolean) => {
    if (!list.id) return;

    if (list.name !== FAVORITES_LIST_NAME
      && !userIsProOrAdmin(currentUser)
      && !userIsTeamMember(currentUser)) {
      showUpgradeModal('Sign up for PRO to add to the list');
      return;
    }

    if (!added) {
      addToList(list);
    } else {
      removeFromList(list);
    }
  };

  const openAddList = () => {
    if (!userIsProOrAdmin(currentUser) && !userIsTeamMember(currentUser)) {
      showUpgradeModal('Sign up for PRO to create new lists');
      return;
    }

    setAdding(true);
  };

  const addList = (list: List) => {
    setLists([
      ...(lists || []),
      list,
    ]);
  };

  const sortBySaved = (a: List) => {
    if (listIds?.includes(a.id)) {
      return -1;
    }
    return 1;
  };

  const sortedListsCopy = Array.from(lists || []).sort(sortBySaved);

  return (
    <Dialog
      open
      onClose={onCancel}
      maxWidth="sm"
      fullWidth
    >
      <div className="absolute right-2 top-2 text-orange">
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="p-4">
        {!adding ? (
          <>
            <h1 className="text-lg text-orange font-bold">
              Save Creator to a List
            </h1>
            <div>
              <MuiList>
                {sortedListsCopy.map((l) => {
                  const isAdded = listIds?.includes(l.id);
                  return (
                    <ListItem key={l.id} disablePadding>
                      <ListItemButton
                        disabled={isSendingData}
                        onClick={() => save(l, isAdded)}
                      >
                        <ListItemIcon>
                          {isAdded ? <CheckIcon color="success" /> : <AddIcon color="inherit" />}
                        </ListItemIcon>
                        <ListItemText>{l.name}</ListItemText>
                        <ListItemIcon>
                          {isAdded && <div className="text-gold"><BookmarkRemove fontSize="large" color="inherit" /></div>}
                        </ListItemIcon>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </MuiList>
            </div>
            <div>
              <button type="button" className="py-4 w-full hover:bg-hintgray" onClick={openAddList}>
                <AddIcon />
                {' '}
                Create a New List
              </button>
            </div>
          </>
        )
          : <AddEditList lists={lists || []} onSave={addList} onCancel={() => setAdding(false)} />}
      </div>
    </Dialog>
  );
};
